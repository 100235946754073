.title {
    font-size: 2em;
}
.block {
    min-height: 50vh;
    background-color: #f2e4e1
    ;
    flex: 1;
    display: flex;
    padding: 20px;
    padding-top: 75px;
    padding-bottom: 75px;
}
.text-wrap {
    max-width: 600px
}
.intro-text {
    font-family: 'Playfair Display', serif;
}