.load-container {
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.8);
  position: fixed;
  left: 0;
  top: 0;
}
.relative {
  position: relative;
  width: 100%;
  height: 100%;
}
.r-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white
}