.App {
  text-align: center;
  flex: 1
}
.center {
  flex: 1;
  align-items: center;
  justify-content: center;
  }
  .image {
    height: 100px
  }
  @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display:ital@0;1&display=swap');

  .main-title-ff, .subtitle, .sub-title {
    font-family: 'Playfair Display', serif;
  }
  .title {
    font-family: 'DM Serif Display', serif;
  }
 div {
    font-family: 'Oswald', sans-serif;
  }
  