.background-image {
    background-image: url('../../images/landing/landing-hq.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: cover;
    height: 60vh;
    flex: 1;
    display: flex;
}
.ib-title {
    font-size: 4em;
}
.subtitle {
    font-size: 1.3em;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    .ib-title {
        font-size: 2.3em;
        
    }
    .subtitle {
        font-size: 1em;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .background-image {
        background-attachment: scroll;
    }
  }