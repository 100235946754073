@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display:ital@0;1&display=swap);
html {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.green {
  background-color: green
}
.red {
  background-color: red
}
.blue {
  background-color: blue
}

.App {
  text-align: center;
  flex: 1 1
}
.center {
  flex: 1 1;
  align-items: center;
  justify-content: center;
  }
  .image {
    height: 100px
  }

  .main-title-ff, .subtitle, .sub-title {
    font-family: 'Playfair Display', serif;
  }
  .title {
    font-family: 'DM Serif Display', serif;
  }
 div {
    font-family: 'Oswald', sans-serif;
  }
  
.center {
flex: 1 1;
justify-content: center;
justify-content: center;
  }
  .border {
    border-top: 1px solid black;
  }
.background-image {
    background-image: url(/static/media/landing-hq.b14de376.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: cover;
    height: 60vh;
    flex: 1 1;
    display: flex;
}
.ib-title {
    font-size: 4em;
}
.subtitle {
    font-size: 1.3em;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    .ib-title {
        font-size: 2.3em;
        
    }
    .subtitle {
        font-size: 1em;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .background-image {
        background-attachment: scroll;
    }
  }
.title {
    font-size: 2em;
}
.block {
    min-height: 50vh;
    background-color: #f2e4e1
    ;
    flex: 1 1;
    display: flex;
    padding: 20px;
    padding-top: 75px;
    padding-bottom: 75px;
}
.text-wrap {
    max-width: 600px
}
.intro-text {
    font-family: 'Playfair Display', serif;
}

.img {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    min-height: 250px;
    max-width: 100%
}
.img-wrap {
    flex: 1 1
}
.cont {
    overflow-x: hidden;
    max-width: 100%;
}

.img-1 {
    background-image: url(/static/media/wafel-naturel.13d32c2b.jpg);
}
.img-2 {
    background-image: url(/static/media/snijplank.254a523f.jpg);
}
.img-3 {
    background-image: url(/static/media/dashboard.7bd4587f.jpg);
}
.img-4 {
    background-image: url(/static/media/wafels-bord.8a16916c.jpg);
}
.img-5 {
    background-image: url(/static/media/truck-front.a8a421dc.jpg);
}
.img-6 {
    background-image: url(/static/media/proef-cat.0c0ab819.jpg);
}
.img-7 {
    background-image: url(/static/media/wafel-slagroom.dd92afac.png);
}
.full-img {
    height: 100% !important;
    width: 100% !important;
    background-color: greenyellow;
}
.row>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.row{
    --bs-gutter-x: 0px !important;
}
.cb-container {
  background-color: #f4f4f4;

}
.email-cb {
  font-family: 'Playfair Display', serif;
}
.container {
  text-align: center;
  max-width: 1000px;
}
.sf-bg {
  background-color: #f2e4e1;
  padding-top: 75px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1 1
}
#validationFormik01 {
  margin-right: 5px
}


.ir-container {
  margin-top: 75px;
  margin-bottom: 75px;
  text-align: center;
}

Img {
  height: 300px;
  object-fit: cover;
}
.nf-container {
  background-color: black;
  color: white !important;
  padding: 40px;
}
.load-container {
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.8);
  position: fixed;
  left: 0;
  top: 0;
}
.relative {
  position: relative;
  width: 100%;
  height: 100%;
}
.r-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white
}
