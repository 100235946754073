
.img {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    min-height: 250px;
    max-width: 100%
}
.img-wrap {
    flex: 1
}
.cont {
    overflow-x: hidden;
    max-width: 100%;
}

.img-1 {
    background-image: url('../../images/collage/wafel-naturel.jpg');
}
.img-2 {
    background-image: url('../../images/collage/snijplank.jpg');
}
.img-3 {
    background-image: url('../../images/collage/dashboard.jpg');
}
.img-4 {
    background-image: url('../../images/collage/wafels-bord.jpg');
}
.img-5 {
    background-image: url('../../images/collage/truck-front.jpg');
}
.img-6 {
    background-image: url('../../images/collage/proef-cat.jpg');
}
.img-7 {
    background-image: url('../../images/collage/wafel-slagroom.png');
}
.full-img {
    height: 100% !important;
    width: 100% !important;
    background-color: greenyellow;
}
.row>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.row{
    --bs-gutter-x: 0px !important;
}