.container {
  text-align: center;
  max-width: 1000px;
}
.sf-bg {
  background-color: #f2e4e1;
  padding-top: 75px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1
}
#validationFormik01 {
  margin-right: 5px
}